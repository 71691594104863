import './App.css';
import { Routes, Route, HashRouter } from 'react-router-dom'
import { Suspense, lazy } from 'react';

const LoginMain = lazy(() => import('./user/login/LoginMain')) ;
const Main = lazy(() => import('./main/Main')) ;
const MarkUp = lazy(() => import('./markup/MarkUp')) ;
const InvitePage = lazy(() => import('./main/workspace/member/InvitePage')) ;
const RelationMain = lazy(() => import('./relation/RelationMain')) ;

function App() {
  // prevent default event.
  document.addEventListener("contextmenu", (e) => {
    e.preventDefault();
  });

  return (
    <div>
      <div className="App">
        <HashRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {/* /markup?groupId=&name= */}
              <Route path="/markup" element={<MarkUp />}></Route>
              {/* /whiteboard?groupId=&whiteboardId=*/}
              {/* /whiteboard?projectId=&whiteboardId=*/}
              <Route path="/whiteboard" element={<RelationMain />}></Route>
              <Route path="/main" element={<Main />}></Route>
              <Route path="/invite" element={<InvitePage />}></Route>
              <Route path="/" element={<LoginMain />}></Route>
            </Routes>
          </Suspense>
        </HashRouter>
      </div>
    </div>
  );
}

export default App;
